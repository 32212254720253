const dev = {
  STRIPE_KEY: "pk_test_pjPpsespGu2U4fvLDbEY9slw00pgGq2mUW",
  s3: {
    REGION: "us-west-2",
    BUCKET: "notes-app2-api-dev-attachmentsbucket-8qzrwcpxj47f"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.russellhtan.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_nc4N8v5wQ",
    APP_CLIENT_ID: "6tto94o374emo8bhr81a42390m",
    IDENTITY_POOL_ID: "us-west-2:69e01b07-43bc-40ce-8e02-52da41c211b4"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_pjPpsespGu2U4fvLDbEY9slw00pgGq2mUW",
  s3: {
    REGION: "us-west-2",
    BUCKET: "notes-app2-api-prod-attachmentsbucket-1qh9zk6hnecho"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.russellhtan.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_DL549ueFs",
    APP_CLIENT_ID: "hbjffj12nbt0e9aac11ooufj2",
    IDENTITY_POOL_ID: "us-west-2:f07b76eb-2807-48da-bd55-7ede6bdf3046"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};